import React, { useState, useEffect, Fragment } from "react";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import AuthService from "../../pages/Authentication/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAlignJustify, faBars } from "@fortawesome/free-solid-svg-icons";
import "../../assets/custom css/custom-header.scss";
import { IoIosGrid, IoIosNotifications } from "react-icons/io";
import Flag from "react-flagkit";

import city3 from "../../assets/utils/images/dropdown-header/city3.jpg";
import city2 from "../../assets/utils/images/dropdown-header/city2.jpg";
import user from "../../assets/utils/images/avatars/user_gen.png";
import logo from "../../assets/utils/images/bosch-logo.png";
// import js from "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  DropdownItem
} from "reactstrap";

library.add(
  faAlignJustify,
  faBars
);


const Topbar = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageFlag(localStorage.getItem("language"));
  }, []);

  const navigate = useNavigate();
  // const currentUser = AuthService.getCurrentUser();
  // const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [langFlag, setlangFlag] = useState('');
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);

  function setLanguageFlag(lang) {
    if (lang == 'en') {
      setlangFlag('GB');
      setActive1(true)
      setActive2(false)
    }
    else {
      setlangFlag('MY');
      setActive1(false)
      setActive2(true)
    }
  };

  function changeLanguage(lang) {
    console.log('language:-', lang);
    localStorage.setItem('language', lang);
    setLanguageFlag(lang);
    i18n.changeLanguage(lang);
  }

  function logOut() {
    // AuthService.logout();
    navigate("/login");
    window.location.reload();
  };

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div"
          className={cx("app-header ")}
          appear={true} timeout={1500} enter={false} exit={false}>
          <div className="grid">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="app-header__logo">
                  <a href="https://bosch-smartlock.com/" className="mt-2">
                    <img src='https://bosch-smartlock.com/wp-content/uploads/2023/07/4-color_Bosch_SL-en_4C_L.png' className="logo-src" alt="BOSCH" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-6 ml-l">
                <a href="#" className="header-submenu">
                  <FontAwesomeIcon icon={["fas", "align-justify"]} className="me-1"  />
                  Products
                </a>
                <a href="#" className="header-submenu">
                  Service
                </a>
                <a href="https://bosch-smartlock.com/reach-our-team/" className="header-submenu">
                  Contact Us
                </a>
              </div>
              <div className="col-sm-2 col-md-4 col-lg-5 right-col">
                <p className="right-col-p">
                  Smart Lock
                </p>
              </div>
            </div> */}
            <nav className="navbar navbar-expand-md " aria-label="Fourth navbar example">
              <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                  <FontAwesomeIcon icon={["fas", "bars"]} className="me-1" /> </button>


                <div className="collapse navbar-collapse" id="navbarsExample04">
                  <ul className="navbar-nav me-auto mb-2 mb-md-0">
                  <li className="nav-item dropdown" role="presentation">
                    <a className="nav-link active" data-bs-toggle="dropdown" href="#">          
                            <FontAwesomeIcon icon={["fas", "align-justify"]} className="me-1 mb-1"  />Products</a>
                            <ul className="dropdown-menu">
                        <li >
                        <a className="dropdown-item" href="https://bosch-smartlock.com/product/id60/" ><span className="menu-text">ID60</span></a></li>
                          </ul>
                  </li>
                    <li className="nav-item dropdown" role="presentation">
                      <a href="#" className="nav-link  dropdown" data-bs-toggle="dropdown">
                        Service</a>
                      <ul className="dropdown-menu">
                        <li >
                          <a className="dropdown-item" href="https://warranty.bosch-smartlock.com/" >
                            <span className="menu-text">Warranty Registration</span></a></li>
                        <li>
                          <a className="dropdown-item" href="https://bosch-smartlock.com/faqs/" target="_blank">
                            <span className="menu-text">FAQs</span></a></li>
                        {/* <li >
                          <a className="dropdown-item" href="https://bosch-smartlock.com/downloads/" target="_blank">
                            <span className="menu-text">Download Center</span>
                          </a>
                        </li> */}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="https://bosch-smartlock.com/reach-our-team/" target="_blank">Contact Us</a>
                    </li>

                  </ul>

                  <div className="col-sm-2 col-md-4 col-lg-5 right-col">
                    <p className="right-col-p">
                      Smart Lock
                    </p>
                  </div>
                </div>
              </div>
            </nav>
          </div>


        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );







  // const navigate = useNavigate();
  // const currentUser = AuthService.getCurrentUser();

  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);

  // useEffect(() => {

  //   if (currentUser) {
  //     setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
  //     setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
  //   }


  //   EventBus.on("logout", () => {
  //     logOut();
  //   });

  //   return () => {
  //     EventBus.remove("logout");
  //   };
  // }, []);

  // function gotoprofile() {
  //   navigate("/profile");
  //   // window.location.reload();
  // }

  // function gotosettings() {
  //   navigate("/settings");
  //   // window.location.reload();
  // }

  // function logOut() {
  //   AuthService.logout();
  //   setShowModeratorBoard(false);
  //   setShowAdminBoard(false);
  //   navigate("/login");
  //   window.location.reload();
  // };

  // const baseURL = process.env.PUBLIC_URL;

  // // render() {
  //   return (
  //     <div className="topbar">

  //       <nav className="navbar-custom" id="navbar-custom">
  //         <ul className="list-unstyled topbar-nav float-end mb-0">
  //           <li className="dropdown notification-list">
  //             <a
  //               className="nav-link dropdown-toggle arrow-none nav-icon"
  //               data-bs-toggle="dropdown"
  //               href="/home"
  //               role="button"
  //               aria-haspopup="false"
  //               aria-expanded="false"
  //             >
  //               <i className="ti ti-bell" />
  //               <span className="alert-badge" />
  //             </a>
  //             <div className="dropdown-menu dropdown-menu-end dropdown-lg pt-0">
  //               <h6 className="dropdown-item-text font-15 m-0 py-3 border-bottom d-flex justify-content-between align-items-center">
  //                 Notifications{" "}
  //                 <span className="badge bg-soft-primary badge-pill">2</span>
  //               </h6>
  //               <div className="notification-menu" data-simplebar>

  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     2 min ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-chart-arcs" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Your order is placed
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         Dummy text of the printing and industry.
  //                       </small>
  //                     </div>

  //                   </div>

  //                 </a>


  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     10 min ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-device-computer-camera" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Meeting with designers
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         It is a long established fact that a reader.
  //                       </small>
  //                     </div>

  //                   </div>

  //                 </a>


  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     40 min ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-diamond" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         UX 3 Task complete.
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         Dummy text of the printing.
  //                       </small>
  //                     </div>

  //                   </div>

  //                 </a>


  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     1 hr ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-drone" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Your order is placed
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         It is a long established fact that a reader.
  //                       </small>
  //                     </div>

  //                   </div>

  //                 </a>


  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     2 hrs ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-users" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Payment Successfull
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         Dummy text of the printing.
  //                       </small>
  //                     </div>

  //                   </div>

  //                 </a>

  //               </div>

  //               <a
  //                 href="/home"
  //                 className="dropdown-item text-center text-primary"
  //               >
  //                 View all <i className="fi-arrow-right" />
  //               </a>
  //             </div>
  //           </li>
  //           <li className="dropdown">
  //             <a
  //               className="nav-link dropdown-toggle nav-user"
  //               data-bs-toggle="dropdown"
  //               href="/home"
  //               role="button"
  //               aria-haspopup="false"
  //               aria-expanded="false"
  //             >
  //               <div className="d-flex align-items-center">
  //                 <img
  //                   src={baseURL+"../assets/images/users/user-4.jpg"}
  //                   alt="profile-user"
  //                   className="rounded-circle me-2 thumb-sm"
  //                 />
  //                 <div>

  //                 <UncontrolledButtonDropdown>
  //                   <DropdownToggle caret="true" className="mb-2 me-2" color="link">
  //                   <small className="d-none d-md-block font-11">Admin
  //                   </small>
  //                   <span className="d-none d-md-block fw-semibold font-12">
  //                   {currentUser.name} <i className="mdi mdi-chevron-down" />
  //                   </span>
  //                   </DropdownToggle>
  //                   <DropdownMenu className="dropdown-menu-rounded">
  //                     <DropdownItem onClick={gotoprofile}>Profile</DropdownItem>
  //                     <DropdownItem onClick={gotosettings}>Settings</DropdownItem>
  //                     <DropdownItem onClick={logOut}>Logout</DropdownItem>
  //                   </DropdownMenu>
  //                 </UncontrolledButtonDropdown>
  //                   {/* <small className="d-none d-md-block font-11">Admin
  //                   </small>
  //                   <span className="d-none d-md-block fw-semibold font-12">
  //                   {currentUser.name} <i className="mdi mdi-chevron-down" />
  //                   </span> */}
  //                 </div>
  //               </div>
  //             </a>

  //             {/* <div className="dropdown-menu dropdown-menu-end">
  //               <a className="dropdown-item" href={"/profile"}>
  //                 <i className="ti ti-user font-16 me-1 align-text-bottom" />
  //                 Profile
  //               </a>
  //               <a className="dropdown-item" href="/settings">
  //                 <i className="ti ti-settings font-16 me-1 align-text-bottom" />
  //                 Settings
  //               </a>
  //               <div className="dropdown-divider mb-0" />
  //               <a className="dropdown-item" href={"/login"} onClick={logOut}>
  //                 <i className="ti ti-power font-16 me-1 align-text-bottom" />
  //                 Logout
  //               </a>
  //             </div> */}
  //           </li>

  //         </ul>

  //         <ul className="list-unstyled topbar-nav mb-0">
  //           <li>
  //             <button
  //               className="nav-link button-menu-mobile nav-icon"
  //               id="togglemenu"
  //             >
  //               <i className="ti ti-menu-2" />
  //             </button>
  //           </li>
  //         </ul>
  //       </nav>

  //     </div>
  //   );
  // }
}

export default Topbar;
