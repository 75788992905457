import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Label, FormGroup, Input, UncontrolledTooltip, InputGroup } from "reactstrap";
import { Form, Field } from "react-final-form";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";
import serialno from "../../../assets/utils/images/serialNo.png";
import sellerName from "../../../assets/utils/images/sellerName.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import Footer from "../../../components/Footer/Footer";

library.add(
    faInfoCircle
)

const Login_url = process.env.REACT_APP_LOGINURL;


function RegisterProduct() {

    localStorage.removeItem("regprod");

    useEffect(() => {
        fetchCountries();
        fetchProducrModels();
    }, []);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [countries, setcountries] = useState([]);
    const [allstates, setallstatess] = useState([]);
    const [cities, setcities] = useState([]);
    const [productmodels, setproductmodels] = useState([]);
    const [country, setCountry] = useState("");
    const [country_id, setCountry_id] = useState("");
    const [stat, setStat] = useState("");
    const [stat_id, setStat_id] = useState("");
    const [city, setCity] = useState("");
    const [city_id, setCity_id] = useState("");
    const [receipt, setReceipt] = useState("");
    const [receipterror, setReceiptError] = useState("");
    const [productmodel, setProductModel] = useState("")
    const [productmodel_id, setProductMode_id] = useState("")

    function fetchCountries() {
        var payload = {
            functionName: "GetCountries",
            "postData": {}
        }
        return axios.post(Login_url + "customers", payload).then((response) => {
            console.log("Countries:-", response.data.data)
            setcountries(response.data.data);
        },
            (error) => {
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                // if(errContent) {
                //   Alerts.swalErrorAlert(errContent)
                // }
                // else {
                //   Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                // }
            })
    };

    const onChangeCountry = (e) => {
        var country_value = e.target.value;
        fetchStates(country_value);
        setCountry(e.target[e.target.selectedIndex].text)
        setCountry_id(country_value);
        setStat("")
        setStat_id("");
        setCity("")
        setCity_id("");

    }

    function fetchStates(value) {
        var payload = {
            functionName: "GetStates",
            "postData": {
                "filter": { "Custom4": value }
            }
        }
        return axios.post(Login_url + "customers", payload).then((response) => {
            console.log("States:-", response.data.data)
            setallstatess(response.data.data);
        },
            (error) => {
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
            })
    };

    const onChangeStat = (e) => {
        console.log("State value:-", e.target.value)
        fetchCities(e.target.value);
        console.log("State Name:-", e.target[e.target.selectedIndex].text)
        setStat(e.target[e.target.selectedIndex].text)
        setStat_id(e.target.value);
        setCity("")
        setCity_id("");
    }

    function fetchCities(value) {
        var payload = {
            functionName: "GetCities",
            "postData": {
                "filter": { "Custom6": value }
            }
        }
        return axios.post(Login_url + "customers", payload).then((response) => {
            console.log("Cities:-", response.data.data)
            setcities(response.data.data);
        },
            (error) => {
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
            })
    };

    const onChangeCity = (e) => {
        console.log("City value:-", e.target.value)
        console.log("City Name:-", e.target[e.target.selectedIndex].text)
        setCity(e.target[e.target.selectedIndex].text)
        setCity_id(e.target.value);
    }

    function fetchProducrModels() {
        var payload = {
            functionName: "GetProducts",
            "postData": {}
        }
        return axios.post(Login_url + "customers", payload).then((response) => {
            console.log("Product Models:-", response.data.data)
            setproductmodels(response.data.data);
        },
            (error) => {
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                // if(errContent) {
                //   Alerts.swalErrorAlert(errContent)
                // }
                // else {
                //   Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                // }
            })
    }

    const onChangeModel = (e) => {
        var prod_model = e.target.value
        console.log(" Prd Model value:-", prod_model)
        // fetchProducrModels(e.target.value);
        console.log("Prd Model Name:-", e.target[e.target.selectedIndex].text)
        setProductModel(e.target[e.target.selectedIndex].text)
        setProductMode_id(prod_model);
    }


    const onFileChange = (e) => {
        console.log(e.target.files)
        var filename = e.target.files[0].name
        console.log("File Name", filename)
        var fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            const convertedPDF = fileReader.result
            console.log("Converted PDF File:-", convertedPDF)
            setReceipt(convertedPDF);
            setReceiptError("")
        }
    }


    const onSubmit = values => {
        console.log(values);
        if (!receipt) {
            setReceiptError("* Product receipt is required.");
            Alerts.swalErrorAlert("Product receipt is required. Please upload a receipt before proceeding to register warranty. Thank You.")
        }
        else {
            setLoading(true);
            var payloadData = {
                functionName: "RegisterWaranty",
                postData: {
                    "customer": {
                        "email": values.email,
                        "password": values.password,
                        "country": country,
                        "salutation": values.salutation,
                        "first_name": values.first_name,
                        "last_name": values.last_name,
                        "house_no": values.house_no,
                        "build_street": values.build_street,
                        "city": city,
                        "state": stat,
                        "zip_code": values.zip_code,
                        "phone": '+60'+values.phone
                    },
                    "prod_model": productmodel,
                    "prod_serial_no": values.prod_serial_no,
                    "seller_name": values.seller_name,
                    "purchase_date": values.purchase_date,
                    "install_date": values.install_date,
                    "receipt": receipt
                }
            }
            return axios.post(Login_url + "customers", payloadData)
                .then((response) => {
                    console.log("Register Warranty API Resp:-", response);
                    setLoading(false);
                    if (response.data.status) {
                        if (response.data.message !== '') {
                            Alerts.swalSuccessAlert(response.data.message).then(res => {
                                console.log("Resp:-", res)
                                if (res === true) {
                                    window.location.href = "https://warranty.bosch-smartlock.com/";
                                }
                            })
                        }
                        else {
                            Alerts.swalSuccessAlert("Thank you for purchasing Bosch Smart Lock. Your warranty registration is successful.").then(res => {
                                console.log("Resp:-", res)
                                if (res === true) {
                                    window.location.href = "https://warranty.bosch-smartlock.com/";
                                }
                            })
                        }
                    }
                    else {
                        if (response.data.message !== '') {
                            Alerts.swalErrorAlert(response.data.message)
                        }
                        else {
                            Alerts.swalErrorAlert("Error: Can't register the warranty at the moment. Please try again later.")
                        }

                    }
                },
                    (error) => {
                        setLoading(false);
                        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        console.log("Error:-", error)
                        Alerts.swalErrorAlert("Network Operation failed.")
                    });

        }

    };

    const validateFtn = (values) => {
        const errors = {};
        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        function validatePhone(phone) {
            var rp = /^(?!.*[-.+])(?!0\d{0,8}|00\d{0,8}|60\d{0,7}|600\d{0,6}|0*0060\d{0,6})[0-9]{9,12}$/;
            return rp.test(String(phone));
        }
        if (!values.email) {
            errors.email = "Email Required.";
        }
        else if (!validateEmail(values.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!values.phone) {
            errors.phone = "Phone Required.";
        }
        else if (!validatePhone(values.phone)) {
            errors.phone = "Please enter a valid Phone No.";
        }
        if (!values.salutation) {
            errors.salutation = "Salutation Required.";
        }
        if (!values.first_name) {
            errors.first_name = "First/Given Name Required.";
        }
        if (!values.last_name) {
            errors.last_name = "Last/Family Name Required.";
        }
        if (!values.house_no) {
            errors.house_no = "This field is required.";
        }
        if (!values.build_street) {
            errors.build_street = "This field is required.";
        }
        // if (!values.country) {
        //     errors.country = "Country Required.";
        // }
        // if (!values.state) {
        //     errors.state = "State Required.";
        // }
        // if (!values.city) {
        //     errors.city = "City Required.";
        // }
        if (country == '') {
            errors.country = "Country Required.";
        }
        if (stat == '') {
            errors.state = "State Required.";
        }
        if (city == '') {
            errors.city = "City Required.";
        }
        if (!values.zip_code) {
            errors.zip_code = "ZIP Code Required.";
        }
        // if (!values.prod_model) {
        //     errors.prod_model = "Product Model Required.";
        // }
        if (productmodel == '') {
            errors.prod_model = "Product Model Required.";
        }
        if (!values.prod_serial_no) {
            errors.prod_serial_no = "Product Serial No Required.";
        }
        if (!values.seller_name) {
            errors.seller_name = "Seller Name Required.";
        }
        if (!values.purchase_date) {
            errors.purchase_date = "Purchase Date Required.";
        }
        if (!values.install_date) {
            errors.install_date = "Installation Date Required.";
        }
        // if (!values.receipt) {
        //     errors.receipt = "This value is Required.";
        // }
        if (!values.accept) {
            errors.accept = "Please accept to proceed and register your warranty";
        }
        return errors;
    };

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <Form onSubmit={onSubmit}
                        validate={validateFtn}
                        render={({ handleSubmit, values, submitting, validating, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <LoadingOverlay tag="div" active={loading}
                                    styles={{ overlay: (base) => ({ ...base }) }}
                                    spinner={<DNALoader />}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-10 col-md-8 col-lg-5">
                                                <h2 className="fs-2 my-4 fw-bold">Product Warranty</h2>

                                                <h4 className="fs-3 my-3"> Contact information</h4>
                                                <FormGroup>
                                                    <Field name="salutation">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Salutation *</Label>
                                                                <Input {...input} type="select"
                                                                    invalid={meta.error && meta.touched}
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    <option value="Mr.">Mr.</option>
                                                                    <option value="Mrs.">Mrs.</option>
                                                                    <option value="Miss.">Miss.</option>
                                                                </Input>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="first_name">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">First/Given Name *</Label>
                                                                <Input  {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="last_name">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Last/Family Name *</Label>
                                                                <Input  {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="email">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Email Address *</Label>
                                                                <Input {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="house_no">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">House No. / Lot No. / Floor / Block No. </Label>
                                                                <Input {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="build_street">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Building Name/Street Adress *</Label>
                                                                <Input {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="country" >
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2" >Country *</Label>
                                                                <Input {...input} type="select" className="form-control"
                                                                    invalid={meta.error && meta.touched} onChange={onChangeCountry} value={country_id}
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    {countries &&
                                                                        countries.map((count, ind) => (
                                                                            <option key={ind} value={count._id}>{count.Custom1}</option>
                                                                        ))
                                                                    }
                                                                </Input>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="state">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">State *</Label>
                                                                <Input {...input} type="select" className="form-control"
                                                                    invalid={meta.error && meta.touched} onChange={onChangeStat} value={stat_id}
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    {allstates &&
                                                                        allstates.map((sta, inde) => (
                                                                            <option key={inde} value={sta._id}>{sta.Custom3}</option>
                                                                        ))
                                                                    }
                                                                </Input>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="city">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">City *</Label>
                                                                <Input {...input} type="select" className="form-control"
                                                                    invalid={meta.error && meta.touched} onChange={onChangeCity} value={city_id}
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    {cities &&
                                                                        cities.map((scity, index) => (
                                                                            <option key={index} value={scity._id}>{scity.Custom5}</option>
                                                                        ))
                                                                    }
                                                                </Input>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="zip_code">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Zip Code *</Label>
                                                                <Input {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="phone">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Phone Number *</Label>
                                                                <InputGroup className="mb-3">
                                                                    <div className="input-group-text">
                                                                        +60
                                                                    </div>
                                                                    <Input {...input} type="number" className="form-control"
                                                                        invalid={meta.error && meta.touched} placeholder="" />
                                                                </InputGroup>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <h4 className="fs-2 my-3"> Product Information </h4>

                                                <FormGroup>
                                                    <Field name="prod_model">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Product Model *</Label>
                                                                <Input {...input} type="select" className="form-control"
                                                                    invalid={meta.error && meta.touched} onChange={onChangeModel} value={productmodel_id}
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    {productmodels &&
                                                                        productmodels.map((prodmodel, indexx) => (
                                                                            <option key={indexx} value={prodmodel.id}>{prodmodel.Custom7}</option>
                                                                        ))
                                                                    }
                                                                </Input>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="prod_serial_no">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2 d-flex justify-content-between align-items-center">Product Serial No.*
                                                                    <FontAwesomeIcon icon={faInfoCircle} id="Tooltipseriel" />
                                                                </Label>
                                                                <UncontrolledTooltip placement="right" target={"Tooltipseriel"}>
                                                                    <img src={serialno} width='270px' height='370px' />
                                                                </UncontrolledTooltip>
                                                                <Input {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="seller_name">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2 d-flex justify-content-between align-items-center">Sellers Name *
                                                                    <FontAwesomeIcon icon={faInfoCircle} id="Tooltipseller" />
                                                                </Label>
                                                                <UncontrolledTooltip placement="right" target={"Tooltipseller"}>
                                                                    <img src={sellerName} width='670px' height='370px' />
                                                                </UncontrolledTooltip>
                                                                <Input {...input} type="text" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="purchase_date">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Date Of Purchase *</Label>
                                                                <Input {...input} type="date" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="install_date">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Date Of Installation *</Label>
                                                                <Input {...input} type="date" className="form-control"
                                                                    invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className="mb-4">
                                                        <Label className="mb-2">Purchase Receipt *</Label>
                                                        <Input name="file" type='file' onChange={onFileChange}
                                                        />
                                                        {receipterror &&
                                                            <span className="text-danger">{receipterror}</span>}
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="mb-5">
                                                    <Field name="accept" type="checkbox">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Input {...input} type="checkbox"
                                                                    invalid={meta.error && meta.touched} />
                                                                <Label for="exampleCheck">
                                                                    By submitting the above information, I acknowledge and accept the terms of Robert Bosch Sdn. Bhd.'s <a className="privacy_a" href="https://drive.google.com/file/d/1NYHOBWE_ZD2SG3ZvRpKrtZRnNk--Ky6C/view" target="_blank">
                                                                        Privacy Policy</a> and Terms and Conditions of Use and agree to being
                                                                    contacted for the purposes of this form. I also acknowledge and accept the terms of Robert Bosch Sdn. Bhd.
                                                                    <a className="privacy_a" href="https://drive.google.com/file/d/1MUgoXRHnY0rnyAjd1-e7G6DNaWBG4At9/view" target="_blank"> Warranty</a> and confirm that I am of legal age to consent to these terms. *
                                                                </Label>
                                                                {meta.error && meta.touched &&
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                            </div>
                                            <div className="d-flex justify-content-between mb-5">
                                                <FormGroup>
                                                    <div className="d-flex justify-content-center mb-5 mt-3">
                                                        <Button color="primary" className="me-3" type="submit" disabled={!valid}>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </form>
                        )}
                    />
                </div>
            </div>
        </Fragment>
    );

}
export default RegisterProduct;