import React, { Fragment, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import { useTranslation } from 'react-i18next';
import Topbar from "./components/Top Bar/Topbar";
import Footer from "./components/Footer/Footer";


import RegisterProduct from "./pages/Customer Panel/Products/RegisterProduct";



const App = () => {

  const {t, i18n } = useTranslation();

  useEffect(() => {
    getColors();
    // getSetLanguage();
  }, []);

  function getColors() {
    const buttonsColor = localStorage.getItem("buttons_color");
    // const topbarColor = localStorage.getItem("topbar_color");
    const topbarColor = '#fff';
    const leftbarColor = localStorage.getItem("leftbar_color");
    const buttonsTextColor = localStorage.getItem("buttons_text_color");
    setColors(buttonsColor, topbarColor, leftbarColor, buttonsTextColor);
  }

  function setColors(buttons_color, topbar_color, leftbar_color, buttons_text_color) {
    document.documentElement.style.setProperty('--buttons-color', buttons_color);
    document.documentElement.style.setProperty('--topbar-color', topbar_color);
    document.documentElement.style.setProperty('--leftbar-color', leftbar_color);
    document.documentElement.style.setProperty('--buttons-text-color', buttons_text_color);
  }


  return (
    <ResizeDetector handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
            "app-container app-theme-white",
            { "fixed-header": true },
            { "fixed-sidebar": true || width < 1500 },
            { "fixed-footer": true },
            { "closed-sidebar": true || width < 1500 },
            {
              "closed-sidebar-mobile": true || width < 1500,
            },
            { "sidebar-mobile-open": false },
            { "body-tabs-shadow-btn": true }
            )}>
             <Topbar />
              <div className="app-main">
                <Routes>
                  <Route exact path={"/"} element={<RegisterProduct />} />
                </Routes>
              </div>
              <Footer />
          </div>
        </Fragment>
      )}
    />
  );
};

export default App;


