import React from "react";
import "../../assets/custom css/bootstrap.css";
// import "../../assets/custom css/font-awesome.min.css";
import "../../assets/custom css/responsive.css";
import "../../assets/custom css/style.css";
import "../../assets/custom css/style.css.map";
// import "../../assets/custom css/style.scss";
// import js from "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

library.add(
    faFacebookF,
    faYoutube,
    faChevronDown,
    faLinkedinIn
)




const Footer = () => {
    

    return (
        <footer className="footer text-center text-sm-start">
           <section className="info_section ">

            <div className="footerpad">
            

                <div className="info_top ">
                    <div className="row info_main_row mbm text-left">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="info_links">
                        <h5 className="widget-title">Bosch Malaysia</h5>
                        <ul className="custom-nav list-unstyled">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9 first">
                                <a href="https://www.bosch.com.my/our-company/bosch-in-malaysia" target="_blank" ><span>About Bosch Malaysia</span></a></li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-10">
                                <a href="https://www.bosch.com.my/corporate-information/?prevent-auto-open-privacy-settings=1" target="_blank"><span>Corporate Info</span></a></li></ul>
                        </div>
                    </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="info_links">
                        <h5 className="widget-title">Service</h5>
                        <ul className="custom-nav list-unstyled">       
                            <li className="menu-item menu-item-type-custom menu-item-object-custom" role="presentation">                
                                <a href="https://warranty.bosch-smartlock.com/"  id="" data-bs-toggle="dropdown"   aria-expanded="false">Warranty Registration </a></li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom" role="presentation">                
    
                                <a href="https://drive.google.com/file/d/1MUgoXRHnY0rnyAjd1-e7G6DNaWBG4At9/view?usp=drive_link" ><span>Product Warranty</span></a>                            </li>
                    
                            <li className=" menu-item-9 first">
                                <a href="https://bosch-smartlock.com/faqs/" target="_blank"><span>FAQ's</span></a>
                            </li>
                            {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-10">
                                <a href="https://bosch-smartlock.com/downloads/" target="_blank"><span>Download Center</span></a>
                            </li> */}
                            </ul>
                        </div>
                    </div>
                   <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="info_links">
                        <h5 className="widget-title">Contact Us</h5>
                        <ul className="custom-nav list-unstyled">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9 first">
                                <a href="https://bosch-smartlock.com/reach-our-team/" target="_blank"><span>Contact Form</span>
                                </a></li></ul></div>
                    </div>

                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="info_links">
                        <h5 className="widget-title">Follow Us</h5>
                        <div className="social_box">
                        <a href="https://www.facebook.com/BoschMalaysia" target="_blank">
                        <FontAwesomeIcon icon={faFacebookF} className="fa"/>
                        </a>
                        <a href="https://www.youtube.com/BoschMalaysiaOfficial" target="_blank">
                        <FontAwesomeIcon icon={faYoutube} className="fa"/>
                        </a>
                        <a href="https://www.linkedin.com/company/bosch_malaysia/mycompany" target="_blank">
                        <FontAwesomeIcon icon={faLinkedinIn} className="fa"/>
                        </a>
                        </div>

                </div>
                    </div>
                    


                </div>
                <div className="info_bottom bordertop">
                    <div className="row pt4">
                    <div className="col-sm12 col-md-4 col-lg-6 copyright">
                        Copyright © 2023 Robert Bosch Sdn. Bhd.
                    </div>
                    <div className="col-sm12 col-md-8 col-lg-6">
                        <div className="bottom-text-block"><ul className="cls text-right mb-0">
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16 first depth-0" role="presentation">
                            <a href="https://bosch-smartlock.com/wp-content/uploads/2023/09/Bosch-SmartLock-Privacy-Setting.pdf" data-level="1" target="_blank" role="menuitem">
                                <span className="menu-item-text">
                                    <span className="menu-text">Privacy Setting</span>
                                </span>
                            </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17 depth-0" role="presentation">
                            <a href="https://www.bosch.com.my/legal-notice/?prevent-auto-open-privacy-settings=1" data-level="1" target="_blank" role="menuitem">
                                <span className="menu-item-text">
                                    <span className="menu-text">Legal Notice</span>
                                </span>
                            </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-18 last depth-0" role="presentation">
                            <a href="https://drive.google.com/file/d/1NYHOBWE_ZD2SG3ZvRpKrtZRnNk--Ky6C/view" target="_blank" data-level="1" role="menuitem">
                                <span className="menu-item-text">
                                    <span className="menu-text">Data Protection Policy</span>
                                </span>
                            </a>
                        </li>
                        </ul>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
            </div>
            </section>
        </footer>
    );
}

export default Footer;
